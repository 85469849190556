import React from 'react';
import { Formik, Form, Field } from 'formik';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import LoginTextField from '../LoginTextField';

const JoinRoom = props => {
  const {
    roomId,
    displayName,
    env,
    role,
    showEnv,
    setCurrentView,
    VIEWS,
    setLoginInfo,
    showRoleSelect,
    ROLES,
    handleNameSubmit,
  } = props;

  return (
    <>
      <Formik
        initialValues={{
          roomId: roomId ? roomId : '',
          displayName: displayName,
          env: env ? env : '',
          role: role ? role : 'guest',
        }}
        validate={values => {
          const errors = {};
          if (!values.displayName) {
            errors.displayName = 'Required';
          }
          if (!values.roomId) {
            errors.roomId = 'Required';
          }
          if (showEnv && !values.env) {
            errors.env = 'Required';
          }
          return errors;
        }}
        onSubmit={values => {
          setLoginInfo(values);
          handleNameSubmit(values);
        }}
      >
        {({ errors, touched, initialValues }) => (
          <Form>
            <div
              className=" flex items-center justify-center w-full px-4 sm:px-6 lg:px-8"
              style={{
                backgroundColor: '#0B0F15',
                minHeight: 'calc(100vh - 64px)',
              }}
            >
              <div className="overflow-hidden shadow rounded-lg max-w-sm w-full px-4 p-6 bg-gray-100">
                <div>
                  <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900 mb-2">
                    {initialValues && (
                      <>
                        <ArrowLeftIcon
                          className="text-gray-700 hover:text-black cursor-pointer"
                          onClick={() => {
                            setCurrentView(VIEWS.CREATE_OR_JOIN);
                            setLoginInfo(loginInfo => {
                              return { ...loginInfo, roomId: '' };
                            });
                          }}
                        />
                        100ms Conference
                      </>
                    )}
                  </h2>
                  {initialValues && initialValues.roomId && (
                    <p className="my-2 text-center text-sm leading-5 text-gray-600">
                      You are about to join room:{' '}
                      <span className="font-bold">{initialValues.roomId}</span>
                    </p>
                  )}
                </div>
                <div className="rounded-md">
                  <div>
                    {initialValues && !initialValues.roomId && (
                      <LoginTextField
                        label="Room ID"
                        name="roomId"
                        className="rounded-t-md"
                        placeholder="Room ID"
                        errors={errors.roomId}
                        touched={touched.roomId}
                      />
                    )}
                  </div>
                  <div className="-mt-px">
                    {initialValues && (
                      <LoginTextField
                        label="Username"
                        name="displayName"
                        className={
                          !(initialValues && !initialValues.roomId) &&
                          'rounded-t-md'
                        }
                        placeholder="Username"
                        errors={errors.displayName}
                        touched={touched.displayName}
                      />
                    )}
                  </div>
                  <div className="-mt-px">
                    {initialValues ? (
                      showRoleSelect ? (
                        <LoginTextField
                          label="Role"
                          name="role"
                          as={showRoleSelect ? 'select' : null}
                          className={!showEnv && 'rounded-b-md'}
                          placeholder="Role"
                          disabled={role === ROLES.VIEWER}
                          errors={errors.role}
                          touched={touched.rol}
                        >
                          <option value="">Select Role</option>
                          {Object.values(ROLES).map((role, index) => (
                            <option
                              key={index}
                              value={role}
                              className="capitalize"
                            >
                              {role}
                            </option>
                          ))}
                        </LoginTextField>
                      ) : (
                        <LoginTextField
                          label="Role"
                          name="role"
                          className={!showEnv && 'rounded-b-md'}
                          placeholder="Role"
                          disabled={role === ROLES.VIEWER}
                          errors={errors.role}
                          touched={touched.rol}
                        />
                      )
                    ) : null}
                  </div>
                  {showEnv && (
                    <div className="-mt-px">
                      {initialValues && (
                        <LoginTextField
                          label="Environment"
                          name="env"
                          className="rounded-b-md"
                          placeholder="Environment (qa-in/staging-in/prod-in)"
                          errors={errors.env}
                          touched={touched.env}
                        />
                      )}
                    </div>
                  )}
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                    Join Room
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default JoinRoom;
