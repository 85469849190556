import React from 'react';

const CreateOrJoinRoom = props => {
  const { setCurrentView, VIEWS } = props;
  return (
    <>
      <div
        className=" flex items-center justify-center w-full px-4 sm:px-6 lg:px-8"
        style={{
          backgroundColor: '#0B0F15',
          minHeight: 'calc(100vh - 64px)',
        }}
      >
        <div className="overflow-hidden justify-right items-right shadow rounded-lg max-w-sm w-full px-4 py-5 p-6 bg-gray-100">
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900 mb-2">
            100ms Conference
          </h2>

          <div className="mt-6 space-y-2">
            <button
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none transition duration-150 ease-in-out"
              onClick={() => {
                setCurrentView(VIEWS.CREATE);
              }}
            >
              Create Room
            </button>
            <button
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-700 hover:border-indigo-700 focus:outline-none border-indigo-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
              onClick={() => {
                setCurrentView(VIEWS.JOIN);
              }}
            >
              Join Room
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrJoinRoom;
