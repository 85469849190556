import React from 'react';
import { deviceSupport } from '../../utils';

const SupportedCheck = props => {
  const { setIsSupported } = props;
  return (
    <>
      <div
        className=" flex items-center justify-center w-full px-4 sm:px-6 lg:px-8"
        style={{
          backgroundColor: '#0B0F15',
          minHeight: 'calc(100vh - 64px)',
        }}
      >
        <div className="overflow-hidden shadow rounded-lg max-w-sm w-full px-4 py-5 p-6 bg-gray-100 my-3">
          <div className="">
            <h2 className="mt-2 text-center text-3xl leading-9 font-extrabold text-gray-900">
              100ms Conference
            </h2>

            <p className="mt-2 text-center text-sm leading-5 text-gray-600 mb-2">
              {deviceSupport().failureCause == 'iOS' ? (
                <span>
                  100ms users might face issues on iOS devices. Please open the
                  link on another device for the best experience. If you wish to
                  continue on iOS, click continue.
                </span>
              ) : (
                <span>
                  We recommend using Google Chrome for the best experience.
                </span>
              )}
            </p>
            <div className="mt-6">
              {deviceSupport().failureCause == 'browser' && (
                <a
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 hover:text-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  href="https://www.google.com/chrome/"
                  target="_blank"
                >
                  Download Google Chrome
                </a>
              )}
              <button
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-700 hover:border-indigo-700 focus:outline-none border-indigo-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mt-3"
                onClick={() => {
                  setIsSupported(true);
                }}
              >
                Continue in current browser
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportedCheck;
