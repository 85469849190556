import React from 'react';
import { Button, Modal, notification } from 'antd';
import { Controls } from './components/Controls';
import '../styles/css/conference.scss';
import { Gallery } from './components/Conference/gallery';
import { Pinned } from './components/Conference/pinned';
import PeerState, { onRoomStateChange } from './utils/state';
import { getLocalStreamException } from './utils';
import { AppContext } from './stores/AppContext';

const modes = {
  GALLERY: 'GALLERY',
  PINNED: 'PINNED',
};

class Conference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      streams: [],
      streamInfo: [],
      localStream: null,
      localScreen: null,
      audioMuted: false,
      videoMuted: false,
      mode: modes.GALLERY,
      pinned: false,
      localStreamError: null,
    };
  }

  componentDidMount = () => {
    const { client } = this.props;
    client.on('stream-add', this._handleAddStream);
    client.on('stream-remove', this._handleRemoveStream);
    this.roomStateUnsubscribe = onRoomStateChange(
      client.rid,
      peers => {
        const streamsMap = peers.reduce((a, c) => {
          return { ...a, ...c.streams };
        }, {});
        this.setState({ streamInfo: streamsMap });
        const newStreams = this.state.streams.map(stream => {
          return { ...stream, ...streamsMap[stream.mid] };
        });

        this.setState({ streams: newStreams });
      },

      console.error
    );
  };

  updateLocalPeerState = () => {
    this.peerState = new PeerState({
      mid: this.state.localStream.mid,
      uid: this.props.client.uid,
      rid: this.props.client.rid,
    });

    this.peerState.update({
      audioEnabled: true,
      videoEnabled: true,
    });

    this.peerState.onRequest(request => {
      const isMuted = this.state.audioMuted;
      if (request.mute) {
        if (isMuted) return;
        this.muteMediaTrack('audio', false);
      } else {
        if (!isMuted) return;
        this.muteMediaTrack('audio', true);
      }
    });
  };

  componentWillUnmount = () => {
    const { client } = this.props;
    if (client) {
      client.off('stream-add', this._handleAddStream);
      client.off('stream-remove', this._handleRemoveStream);
    }
    this.roomStateUnsubscribe && this.roomStateUnsubscribe();
  };

  cleanUp = async () => {
    let { localStream, localScreen, streams } = this.state;
    await this.setState({ localStream: null, localScreen: null, streams: [] });

    // streams.forEach(async item => {
    //   await this.client.unsubscribe(item.stream,this.client.rid);
    // });

    await this._unpublish(localStream);
    await this._unpublish(localScreen);
    // this.peerStateUnsubscribe();
    this.peerState && this.peerState.delete();
  };

  tuneLocalStream = participantCount => {
    const bitrateBreakpoint = 6;
    if (participantCount >= bitrateBreakpoint) {
      if (this.bitrate !== 100) {
        this.bitrate = 100;
        this.props.client.applyConstraints(
          { bitrate: this.bitrate },
          this.props.client.local
        );
      }
      return;
    }
    if (this.bitrate === 100) {
      this.bitrate = this.props.settings.bandwidth;
      this.props.client.applyConstraints(
        { bitrate: this.bitrate },
        this.props.client.local
      );
    }
  };

  _notification = (message, description) => {
    notification.info({
      message: message,
      description: description,
      placement: 'bottomRight',
    });
  };

  _unpublish = async stream => {
    const { client } = this.props;
    if (stream) {
      await this._stopMediaStream(stream);
      await client.unpublish(stream, client.rid);
    }
  };

  muteMediaTrack = (type, enabled) => {
    let { localStream } = this.state;
    if (!localStream) {
      return;
    }
    if (enabled) {
      localStream.unmute(type);
    } else {
      localStream.mute(type);
    }

    if (type === 'audio') {
      this.setState({ audioMuted: !enabled });
      this.peerState && this.peerState.update({ audioEnabled: enabled });
    } else if (type === 'video') {
      this.setState({ videoMuted: !enabled });
      this.peerState && this.peerState.update({ videoEnabled: enabled });
    }
  };

  handleLocalStream = async () => {
    const {
      client,
      settings,
      localVideoEnabled,
      localAudioEnabled,
    } = this.props;

    client
      .getLocalStream({
        codec: settings.codec.toUpperCase(),
        resolution: settings.resolution,
        bitrate: settings.bandwidth,
        frameRate: settings.frameRate,
        // We always get the audio/video track but mute/unmute based on the preference during preview
        shouldPublishAudio: true,
        shouldPublishVideo: true,
        advancedMediaConstraints: {
          video: {
            deviceId: settings.selectedVideoDevice,
          },
          audio: {
            deviceId: settings.selectedAudioDevice,
            advanced: [
              { googEchoCancellation: { exact: true } },
              { googExperimentalEchoCancellation: { exact: true } },
              { autoGainControl: { exact: true } },
              { noiseSuppression: { exact: true } },
              { googHighpassFilter: { exact: true } },
              { googAudioMirroring: { exact: true } },
            ],
          },
        },
      })
      .then(localStream => {
        localStream.getAudioTracks().forEach(track => {
          if ('contentHint' in track) {
            track.contentHint = 'speech';
          }
        });
        return client.publish(localStream, client.rid, () => {
          notification.info({
            message: 'PublishTimeoutFailure',
            description: 'Publish timed out. Refreshing',
            placement: 'bottomRight',
          });
          setTimeout(() => window.location.reload(), 5000);
        });
      })
      .then(async localStream => {
        this.setState({ localStream }, () => {
          this.updateLocalPeerState();
        });

        if (!localAudioEnabled) {
          await this.muteMediaTrack('audio', this.state.audioMuted);
        }

        if (!localVideoEnabled) {
          await this.muteMediaTrack('video', this.state.videoMuted);
        }
      })
      .catch(error => {
        this.props.setLocalStreamError(error);
      });
  };

  handleScreenSharing = async enabled => {
    let { localScreen } = this.state;
    const { client, settings } = this.props;
    if (enabled) {
      localScreen = await client.getLocalScreen({
        bitrate: 0,
        codec: settings.codec.toUpperCase(),
        // frameRate: 5,
      });
      // localScreen.getVideoTracks().forEach(track => {
      //   if ('contentHint' in track) {
      //     track.contentHint = 'text';
      //     track.degradationPreference = 'maintain-resolution';
      //   }
      // });

      // localScreen.options.video.frameRate.min = 5;
      // localScreen.options.video["width"] = {min: 1280};
      // localScreen.options.video["height"] = {min: 720};

      console.log({ localScreen });
      await client.publish(localScreen, client.rid, () => {
        notification.info({
          message: 'PublishTimeoutFailure',
          description: 'Publish timed out. Refreshing',
          placement: 'bottomRight',
        });
        setTimeout(() => window.location.reload(), 5000);
      });
      let track = localScreen.getVideoTracks()[0];
      if (track) {
        track.addEventListener('ended', () => {
          this.handleScreenSharing(false);
        });
      }
    } else {
      if (localScreen) {
        this._unpublish(localScreen);
        localScreen = null;
        if (
          this.state.mode === modes.PINNED &&
          this.state.pinned === client.uid + '-screen'
        ) {
          this.setState({
            mode: modes.GALLERY,
          });
        }
      }
    }
    this.setState({ localScreen });
  };

  _stopMediaStream = async stream => {
    let tracks = stream.getTracks();
    for (let i = 0, len = tracks.length; i < len; i++) {
      await tracks[i].stop();
    }
  };

  _handleAddStream = async (room, peer, streamInfo) => {
    const { client } = this.props;
    let streams = [];
    try {
      let stream = await client.subscribe(streamInfo.mid, room, () => {
        notification.info({
          message: 'SubscribeTimeoutFailure',
          description: 'Subscribe timed out. Refreshing',
          placement: 'bottomRight',
        });
        setTimeout(() => window.location.reload(), 5000);
      });
      console.log(`Got stream for ${peer.name}`, stream);
      stream.info = { name: peer.name }; // @NOTE: Just because stream is expected to have info in this format at the moment by the UI
      // this.startRecording(stream, stream.mid);
      console.log('IN _handleAddStream: ', peer);
      if ((this.state.streamInfo, stream.mid)) {
        streams.push({
          mid: stream.mid,
          stream,
          sid: stream.mid,
          screenshare: streamInfo.screen,
          ...this.state.streamInfo[stream.mid],
        });
        console.log('IN_handleAddStream_IN_IF_PART');
      } else {
        streams.push({ mid: stream.mid, stream, sid: streamInfo.mid });
        console.log('IN_handleAddStream_IN_ELSE_PART');
      }

      console.log(streams);

      this.setState(
        prevState => {
          return { streams: [...prevState.streams, ...streams] };
        },
        () => {
          console.log('FINAL STREAMS IN STATE ARE', this.state.streams);
          this.tuneLocalStream(this.state.streams.length);
        }
      );
    } catch (error) {
      console.error(`ERROR: Error in subscribing`, error.message);
      this._notification(`ERROR: Error in subscribing`, error.message);
      this.props.cleanUp();
    }
  };

  _handleRemoveStream = async (room, peer, streamInfo) => {
    this.setState(prevState => {
      let streams = [...prevState.streams];
      let filteredStreams = streams.filter(item => item.sid !== streamInfo.mid);

      return { streams: filteredStreams };
    });

    if (
      this.state.mode === modes.PINNED &&
      this.state.pinned === streamInfo.mid
    ) {
      this.setState({
        mode: modes.GALLERY,
      });
    }
  };

  _onRequest = (uid, request) => {
    this.peerState && this.peerState.setRequest(uid, request);
  };

  _onChangeVideoPosition = data => {
    let id = data.id;
    let index = data.index;
    console.log('_onChangeVideoPosition id:' + id + '  index:' + index);

    if (index == 0) {
      return;
    }

    const streams = this.state.streams;
    let first = 0;
    let big = 0;
    for (let i = 0; i < streams.length; i++) {
      let item = streams[i];
      if (item.mid == id) {
        big = i;
        break;
      }
    }

    let c = streams[first];
    streams[first] = streams[big];
    streams[big] = c;

    this.setState({ streams: streams });
  };

  render = () => {
    const { client, role } = this.props;
    const {
      streams,
      localStream,
      localScreen,
      audioMuted,
      videoMuted,
    } = this.state;
    const id = client ? client.uid : null;
    let videoCount = streams.length;
    if (localStream) videoCount++;
    if (localScreen) videoCount++;

    if (client)
      return (
        <>
          {this.state.mode === modes.PINNED ? (
            <Pinned
              streams={streams}
              audioMuted={audioMuted}
              videoMuted={videoMuted}
              videoCount={videoCount}
              localStream={localStream}
              localScreen={localScreen}
              client={client}
              id={id}
              loginInfo={this.props.loginInfo}
              pinned={this.state.pinned}
              onUnpin={() => {
                this.setState({
                  mode: modes.GALLERY,
                });
              }}
              onRequest={this._onRequest}
            />
          ) : (
            <Gallery
              streams={streams}
              audioMuted={audioMuted}
              videoMuted={videoMuted}
              videoCount={videoCount}
              localStream={localStream}
              localScreen={localScreen}
              client={client}
              id={id}
              loginInfo={this.props.loginInfo}
              onPin={streamId => {
                this.setState({
                  mode: modes.PINNED,
                  pinned: streamId,
                });
              }}
              onRequest={this._onRequest}
            />
          )}
          <AppContext.Consumer>
            {context => (
              <Controls
                role={role}
                isMuted={this.state.audioMuted}
                isCameraOn={!this.state.videoMuted}
                screenSharingEnabled={context.roomState.screenSharingEnabled}
                onScreenToggle={this.props.onScreenToggle}
                onLeave={this.props.onLeave}
                onMicToggle={() => {
                  this.muteMediaTrack('audio', this.state.audioMuted);
                }}
                onCamToggle={() => {
                  this.muteMediaTrack('video', this.state.videoMuted);
                }}
                onChatToggle={this.props.onChatToggle}
                isChatOpen={this.props.isChatOpen}
                loginInfo={this.props.loginInfo}
                hasUnreadMessages={this.props.hasUnreadMessages}
              />
            )}
          </AppContext.Consumer>
        </>
      );
    return <></>;
  };
}
export default Conference;
