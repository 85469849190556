import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  SingleSelect,
  closeMediaStream,
  updateInputDevices,
  getUserMedia,
  attachMediaStream,
} from '../utils';
import { camOffIcon, camOnIcon, micOffIcon, micOnIcon } from './icons';

export default function Preview({
  handleSubmit,
  roomId,
  displayName,
  goToJoinRoom,
  setLocalStreamError,
  role,
  ROLES,
}) {
  const [previewStream, setPreviewStream] = useState(null);
  const [streams, setStreams] = useState([]);
  const [localVideoEnabled, setLocalVideoEnabled] = useState(true);
  const [localAudioEnabled, setLocalAudioEnabled] = useState(true);
  const [audioDevices, setAudioDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState(null);

  const startPreview = () => {
    closeMediaStream(previewStream);
    let videoElement, soundMeterProcess;
    videoElement = document.getElementById('previewVideo');
    // this.soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
    // soundMeterProcess = this.soundMeterProcess;

    let constraints = {
      audio:
        localAudioEnabled && selectedAudioDevice
          ? { deviceId: { exact: selectedAudioDevice } }
          : true,
      video:
        localVideoEnabled && selectedVideoDevice
          ? { deviceId: { exact: selectedVideoDevice } }
          : true,
    };

    getUserMedia(constraints)
      .then(stream => {
        streams.push(stream);
        setStreams(streams);
        setPreviewStream(stream);
        attachMediaStream(videoElement, stream);
      })
      .catch(error => {
        console.error(error);
        setLocalStreamError(error);
      });
  };

  useEffect(() => {
    updateInputDevices().then(({ videoDevices, audioDevices }) => {
      setAudioDevices(audioDevices);
      setVideoDevices(videoDevices);
      !selectedAudioDevice &&
        audioDevices[0] &&
        setSelectedAudioDevice(audioDevices[0].deviceId);
      !selectedVideoDevice &&
        videoDevices[0] &&
        setSelectedVideoDevice(videoDevices[0].deviceId);
    });
    if (
      selectedAudioDevice &&
      selectedVideoDevice &&
      !previewStream &&
      role !== ROLES.VIEWER
    ) {
      startPreview();
    } else {
      return () => {
        closeMediaStream(previewStream);
      };
    }
  }, [previewStream]);

  useEffect(() => {
    if (selectedAudioDevice && selectedVideoDevice && role !== ROLES.VIEWER) {
      startPreview();
    }
    return () => {
      closeMediaStream(previewStream);
    };
  }, [selectedAudioDevice, selectedVideoDevice]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        selectedAudioDevice: selectedAudioDevice || '',
        selectedVideoDevice: selectedVideoDevice || '',
      }}
      onSubmit={values => {
        handleSubmit({
          selectedDevices: values,
          enabledAV: {
            audioEnabled: localAudioEnabled,
            videoEnabled: localVideoEnabled,
          },
        });
      }}
    >
      {({ initialValues }) => {
        return (
          <Form>
            <div
              className="flex items-center justify-center w-full py-12 px-4 sm:px-6 lg:px-8"
              style={{
                backgroundColor: '#0B0F15',
                minHeight: 'calc(100vh - 64px)',
              }}
            >
              <div className="overflow-hidden shadow rounded-lg max-w-sm w-full px-4 py-5 p-6 bg-gray-100">
                <div className="">
                  <h2 className="mt-2 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    100ms Conference
                  </h2>
                  <p className="mt-2 text-center text-sm leading-5 text-gray-600 mb-2">
                    You are about to join{' '}
                    <span className="font-semibold">{roomId}</span> as{' '}
                    <span className="font-semibold">{displayName}</span>
                    <button
                      className="rounded-md px-2 py-1 hover:bg-indigo-500 ml-1 border transition duration-150 ease-in-out"
                      onClick={() => {
                        goToJoinRoom();
                      }}
                    >
                      Change
                    </button>
                  </p>
                </div>
                {role !== ROLES.VIEWER && (
                  <>
                    <div className="mb-3">
                      <div className="relative h-48 bg-black rounded-md mb-1">
                        <video
                          id="previewVideo"
                          autoPlay
                          playsInline
                          muted={true}
                          className={`rounded-md h-full w-full ${
                            !localVideoEnabled && 'hidden'
                          }`}
                        ></video>
                        <div className="absolute bottom-0 w-full flex justify-center pb-1">
                          <button
                            onClick={e => {
                              e.preventDefault();
                              setLocalVideoEnabled(!localVideoEnabled);
                            }}
                            className={`py-1 px-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 active:bg-indigo-700 transition duration-150 ease-in-out ${
                              localVideoEnabled
                                ? 'bg-opacity-50 bg-gray-600'
                                : 'bg-indigo-600'
                            }`}
                          >
                            {localVideoEnabled ? camOnIcon : camOffIcon}
                          </button>

                          <button
                            onClick={e => {
                              e.preventDefault();
                              setLocalAudioEnabled(!localAudioEnabled);
                            }}
                            className={`ml-1 py-1 px-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out ${
                              localAudioEnabled
                                ? 'bg-opacity-50 bg-gray-600'
                                : 'bg-indigo-600'
                            }`}
                          >
                            {localAudioEnabled ? micOnIcon : micOffIcon}
                          </button>
                        </div>
                      </div>
                      {localAudioEnabled && (
                        <div className="px-1">
                          <div
                            style={{
                              // width: audioEnabled
                              //   ? this.state.audioLevel + 'px'
                              //   : '1px',
                              height: '4px',
                              backgroundColor: '#8dc63f',
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                    <div className="rounded-md shadow-sm">
                      {initialValues.selectedAudioDevice && (
                        <div>
                          <Field
                            label="Audio Input"
                            name="selectedAudioDevice"
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                            placeholder="Audio Input"
                            component={SingleSelect}
                            options={audioDevices}
                            onChange={device => setSelectedAudioDevice(device)}
                          />
                        </div>
                      )}
                      {initialValues.selectedVideoDevice && (
                        <div className="-mt-px">
                          <Field
                            label="Video Input"
                            name="selectedVideoDevice"
                            className={`appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5`}
                            placeholder="Video Input"
                            component={SingleSelect}
                            options={videoDevices}
                            onChange={device => setSelectedVideoDevice(device)}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className="mt-0">
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                    Join
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
