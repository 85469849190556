import React, { useEffect, useState } from 'react';
import { getRequest, deviceSupport } from '../src/utils';
import { isEmpty } from 'lodash';
import '../styles/css/login.scss';

import { ROLES } from './constants';
import Preview from './preview/Preview'; //TODO move preview to correct component
import CreateOrJoinRoom from './components/LoginInfo/CreateOrJoinRoom';
import CreateRoom from './components/LoginInfo/CreateRoom';
import JoinRoom from './components/LoginInfo/JoinRoom';
import Permission from './components/Permission/Permission';
import SupportedCheck from './components/SupportedCheck/SupportedCheck';

const VIEWS = {
  CREATE_OR_JOIN: 'CREATE_OR_JOIN',
  CREATE: 'CREATE',
  JOIN: 'JOIN',
  PERMISSION: 'PERMISSION',
  PREVIEW: 'PREVIEW',
};

//TODO change all file names to lowercase

const LoginForm = props => {
  const {
    loginInfo,
    setLoginInfo,
    handleLogin,
    setLocalStreamError,
    setSettings,
    setRoomState,
  } = props;
  const [currentView, setCurrentView] = useState(VIEWS.CREATE_OR_JOIN);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [isSupported, setIsSupported] = useState(deviceSupport().supported);
  const [dummyCount, setDummyCount] = useState(0); //This count is updated to forcibly check which screen to render if no other value has changed
  const showEnv = !Boolean(process.env.SFU_ENV);
  const showRoleSelect = Boolean(process.env.SFU_ENV);

  useEffect(() => {
    let role = '';
    let roomId = '';
    let env = process.env.SFU_ENV || '';
    let displayName =
      localStorage.getItem('loginInfo.displayName') ||
      loginInfo.displayName ||
      '';

    if (getRequest() && getRequest().hasOwnProperty('role')) {
      role = getRequest().role;
    }
    if (getRequest() && getRequest().hasOwnProperty('room')) {
      roomId = getRequest().room;
    }
    if (getRequest() && getRequest().hasOwnProperty('env')) {
      env = getRequest().env;
    }

    setLoginInfo({
      role,
      roomId,
      env,
      displayName,
    });
  }, []);

  const updateView = () => {
    //If added for extra redundancy
    if (loginInfo) {
      const { role, displayName, roomId, env } = loginInfo;
      if (roomId !== '' && role === ROLES.LIVE_RECORD) {
        console.log('In live record');
        if (displayName === '') {
          setLoginInfo(loginInfo => {
            return { ...loginInfo, displayName: 'live-record' };
          }); //This has audioContext issues but that's ok because this is used internally by a bot
        } else {
          handleLogin();
        }
      } else if (displayName !== '' && roomId !== '' && env !== '') {
        //This makes role optional
        if (permissionGranted) {
          setCurrentView(VIEWS.PREVIEW);
        } else {
          setCurrentView(VIEWS.PERMISSION);
        }
      } else {
        let view = VIEWS.CREATE_OR_JOIN;
        if (roomId != '') {
          view = VIEWS.JOIN;
        }
        setCurrentView(view);
      }
    }
  };

  useEffect(() => {
    if (loginInfo) {
      updateView();
    }
  }, [
    loginInfo.role,
    loginInfo.displayName,
    loginInfo.roomId,
    loginInfo.env,
    permissionGranted,
    dummyCount,
  ]);

  const handleNameSubmit = values => {
    setLoginInfo(values);
    setDummyCount(count => count + 1);
  };

  const handleSubmit = values => {
    setSettings({
      selectedAudioDevice: values.selectedDevices.selectedAudioDevice,
      selectedVideoDevice: values.selectedDevices.selectedVideoDevice,
    });
    setRoomState({
      localAudioEnabled: values.enabledAV.audioEnabled,
      localVideoEnabled: values.enabledAV.videoEnabled,
    });

    handleLogin();
  };

  return (
    <>
      {isSupported && (
        <div className="relative z-0">
          {currentView && currentView === VIEWS.CREATE_OR_JOIN && (
            <CreateOrJoinRoom
              VIEWS={VIEWS}
              setCurrentView={setCurrentView}
            ></CreateOrJoinRoom>
          )}

          {currentView && currentView === VIEWS.CREATE && (
            <CreateRoom
              displayName={loginInfo.displayName}
              env={process.env.SFU_ENV || ''}
              setLoginInfo={setLoginInfo}
              handleNameSubmit={handleNameSubmit}
              setCurrentView={setCurrentView}
              VIEWS={VIEWS}
              showRoleSelect={showRoleSelect}
              ROLES={ROLES}
              showEnv={showEnv}
            ></CreateRoom>
          )}
          {currentView && currentView === VIEWS.JOIN && (
            <JoinRoom
              roomId={loginInfo.roomId}
              displayName={loginInfo.displayName}
              env={loginInfo.env}
              role={loginInfo.role}
              showEnv={showEnv}
              setCurrentView={setCurrentView}
              VIEWS={VIEWS}
              setLoginInfo={setLoginInfo}
              showRoleSelect={showRoleSelect}
              ROLES={ROLES}
              handleNameSubmit={handleNameSubmit}
            ></JoinRoom>
          )}
          {currentView && currentView === VIEWS.PERMISSION && (
            <Permission
              setCurrentView={setCurrentView}
              VIEWS={VIEWS}
              setLocalStreamError={setLocalStreamError}
              permissionGranted={permissionGranted}
              setPermissionGranted={setPermissionGranted}
            ></Permission>
          )}
          {currentView && currentView === VIEWS.PREVIEW && (
            <Preview
              handleSubmit={handleSubmit}
              roomId={loginInfo.roomId}
              displayName={loginInfo.displayName}
              role={loginInfo.role}
              goToJoinRoom={() => {
                setCurrentView(VIEWS.JOIN);
              }}
              ROLES={ROLES}
              setLocalStreamError={setLocalStreamError}
            />
          )}
        </div>
      )}
      {!isSupported && (
        <SupportedCheck setIsSupported={setIsSupported}></SupportedCheck>
      )}
    </>
  );
};

export default LoginForm;
