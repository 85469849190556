import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import { Spin } from 'antd';
import LoginTextField from '../LoginTextField';
import { notificationDisplay } from '../../utils';

const CreateRoom = props => {
  //TODO Abstract RoleSelect in a better way
  const {
    displayName,
    env,
    setLoginInfo,
    handleNameSubmit,
    setCurrentView,
    VIEWS,
    showRoleSelect,
    ROLES,
    showEnv,
  } = props;
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleCreateSubmit = async values => {
    setButtonLoading(true);
    const endpoint = process.env.CREATE_ROOM_ENDPOINT;
    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        room_name: values.roomName,
        recording_info: {
          enabled: values.isRecording,
        },
        env: values.env,
      }),
    }).catch(err => {
      console.error('Error', err);
    });

    if (response.status != 200) {
      const data = await response.json();
      notificationDisplay('Error', data.message, 'error');
    } else {
      const roomEntry = await response.json();
      values.roomId = roomEntry.id;
      notificationDisplay(
        'Room Created',
        `Room Id: ${values.roomId} Room Name: ${values.roomName}`
      );
      handleNameSubmit(values);
      setButtonLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          roomName: '',
          displayName: displayName,
          env: env,
          role: 'Host',
          isRecording: false,
        }}
        validate={values => {
          const errors = {};
          if (!values.displayName) {
            errors.displayName = 'Required';
          }
          if (!values.roomName) {
            errors.roomName = 'Required';
          }
          const validRoomPattern = /^[a-zA-Z0-9-.:_]*$/g;
          if (!validRoomPattern.test(values.roomName)) {
            errors.roomName = 'Accepted characters are a-z, A-Z, 0-9, . - : _';
          }
          if (showEnv && !values.env) {
            errors.env = 'Required';
          }
          return errors;
        }}
        onSubmit={values => {
          handleCreateSubmit(values);
        }}
      >
        {({ errors, touched, initialValues }) => (
          <Form>
            <div
              className=" flex items-center justify-center w-full px-4 sm:px-6 lg:px-8"
              style={{
                backgroundColor: '#0B0F15',
                minHeight: 'calc(100vh - 64px)',
              }}
            >
              <div className="overflow-hidden shadow rounded-lg max-w-sm w-full px-4 p-6 bg-gray-100">
                <div>
                  <h2 className="text-center justify-center items-center text-3xl leading-9 font-extrabold text-gray-900 mb-2">
                    {initialValues && (
                      <>
                        <ArrowLeftIcon
                          className="text-gray-700 hover:text-black cursor-pointer"
                          onClick={() => {
                            setCurrentView(VIEWS.CREATE_OR_JOIN);
                            setLoginInfo(loginInfo => {
                              return { ...loginInfo, roomId: '' };
                            });
                          }}
                        />
                        100ms Conference
                      </>
                    )}
                  </h2>
                </div>
                <div className="rounded-m">
                  <div>
                    <LoginTextField
                      label="Room Name"
                      name="roomName"
                      className="rounded-t-md"
                      placeholder="Room Name"
                      errors={errors.roomName}
                      touched={touched.roomName}
                    />
                  </div>
                  <div className="-mt-px">
                    {initialValues && (
                      <LoginTextField
                        label="Username"
                        name="displayName"
                        className={
                          !(initialValues && !initialValues.roomId) &&
                          'rounded-t-md'
                        }
                        placeholder="Username"
                        errors={errors.displayName}
                        touched={touched.displayName}
                      />
                    )}
                  </div>
                  <div className="-mt-px">
                    {initialValues ? (
                      showRoleSelect ? (
                        <LoginTextField
                          label="Role"
                          name="role"
                          as={showRoleSelect ? 'select' : null} //TODO null should read input
                          className={!showEnv && 'rounded-b-md'}
                          placeholder="Role"
                          disabled={false}
                          errors={errors.role}
                          touched={touched.role}
                        >
                          <option value="">Select Role</option>
                          {Object.values(ROLES).map((role, index) => (
                            <option
                              key={index}
                              value={role}
                              className="capitalize"
                            >
                              {role}
                            </option>
                          ))}
                        </LoginTextField>
                      ) : (
                        <LoginTextField
                          label="Role"
                          name="role"
                          className={!showEnv && 'rounded-b-md'}
                          placeholder="Role"
                          disabled={false}
                          errors={errors.role}
                          touched={touched.role}
                        />
                      )
                    ) : null}
                  </div>

                  {showEnv && (
                    <div className="-mt-px">
                      {initialValues && (
                        <LoginTextField
                          label="Environment"
                          name="env"
                          className="rounded-b-md"
                          placeholder="Environment (qa-in/staging-in/prod-in)"
                          errors={errors.env}
                          touched={touched.env}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="mt-6">
                  <label>
                    <Field type="checkbox" name="isRecording" />
                    {'  '} Record Room?
                  </label>
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                    {/* TODO prevent spinner increasing height */}
                    {buttonLoading ? <Spin /> : 'Create Room'}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateRoom;
