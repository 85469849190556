import React, { useRef, useEffect, useState } from 'react';
import PinIcon from 'mdi-react/PinIcon';
import PinOffIcon from 'mdi-react/PinOffIcon';
import MicrophoneIcon from 'mdi-react/MicrophoneIcon';
import MicrophoneOffIcon from 'mdi-react/MicrophoneOffIcon';

// import useInterval from '../hooks/useInterval';
// import { findRTPFromTransport } from '../utils/webrtc-stats';

export default function MainVideoView({
  id,
  stream,
  vidFit,
  onPin,
  onUnpin,
  label,
  pinned = false,
  audioEnabled = true,
  videoEnabled = true,
  screenshare = false,
  uid,
  onRequest,
}) {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  // const [audioLevelLabel, setAudioLevelLabel] = useState(0);
  // const [audioLevelCircleSize, setAudioLevelCircleSize] = useState(0);

  useEffect(() => {
    videoRef.current.srcObject = stream;

    return () => {
      videoRef.current.srcObject = null;
    };
  }, [videoRef]);

  // useInterval(() => {
  //   if (stream === null || stream === undefined) return;
  //   const statsSource = findRTPFromTransport(
  //     stream.transport,
  //     'audio',
  //     'receiver'
  //   );
  //   statsSource.getStats().then(reports => {
  //     reports.forEach(report => {
  //       if (report.type !== 'inbound-rtp') return;
  //       const audioLevel = Math.max(0, report.audioLevel || 0);
  //       setAudioLevelLabel(
  //         Math.round((audioLevel + Number.EPSILON) * 100000) / 100000
  //       );

  //       if (containerRef.current) {
  //         const circleWidth = Math.min(24, 0.5 * Math.sqrt(audioLevel));
  //         setAudioLevelCircleSize(circleWidth);
  //       }
  //     });
  //   });
  // }, 300);

  label = label || stream.info.name;

  return (
    <div
      ref={containerRef}
      className="w-full max-w-full h-full max-h-full flex justify-center items-center relative p-1"
    >
      {!screenshare && !videoEnabled && (
        <div className="absolute left-0 right-0 mx-auto text-center z-10">
          <img
            className="inline rounded-full"
            src={`https://ui-avatars.com/api/?background=random&name=${label}`}
            alt={label}
          />
        </div>
      )}
      <video
        ref={videoRef}
        id={id}
        autoPlay
        playsInline
        muted={false}
        className={`h-full rounded shadow-lg`}
      />
      <div className="absolute top-0 right-0 pt-2 w-full flex justify-center items-center">
        <span
          className="px-3 bg-indigo-900 rounded text-white inline-block bg-opacity-75 h-6 inline-block mr-0.5"
          style={{ lineHeight: '1.5rem' }}
        >
          {label || stream.info.name}
        </span>
        {uid && (
          <button
            onClick={e => {
              console.log(e, 'mute/unmute', uid);
              onRequest(uid, { mute: audioEnabled });
            }}
            className={`w-6 h-6 bg-opacity-50 rounded flex items-center justify-center mx-0.5 ${
              audioEnabled ? 'bg-gray-800 hover:bg-indigo-500' : 'bg-red-500'
            }`}
          >
            {audioEnabled ? (
              <MicrophoneIcon className="text-indigo-100 w-4 h-4" />
            ) : (
              <MicrophoneOffIcon className="text-red-100 w-4 h-4" />
            )}
          </button>
        )}
        {onPin && (
          <button
            className="w-6 h-6 bg-gray-800 bg-opacity-50 hover:bg-indigo-500 rounded flex items-center justify-center ml-0.5"
            onClick={onPin}
          >
            <PinIcon className="w-4 h-4 text-indigo-100" />
          </button>
        )}
        {onUnpin && (
          <button
            className="w-6 h-6 bg-red-500 bg-opacity-50 hover:bg-red-600 rounded flex items-center justify-center ml-0.5"
            onClick={onUnpin}
          >
            <PinOffIcon className="w-4 h-4 text-white" />
          </button>
        )}
        {/* <span
          className={`mx-3 bg-indigo-900 `}
          style={{
            // Margin such that the circle do not shift label views
            margin: `${(24 - audioLevelCircleSize) / 2 + 4}px`,
            width: `${audioLevelCircleSize}px`,
            height: `${audioLevelCircleSize}px`,
            borderRadius: '50%',
          }}
        />
        <span
          className="px-3 bg-indigo-900 rounded text-white inline-block bg-opacity-75 h-6 inline-block mr-0.5"
          style={{ lineHeight: '1.5rem' }}
        >
          {`AudioLevel ${audioLevelLabel}`}
        </span> */}
      </div>
    </div>
  );
}
