import {
  closeMediaStream,
  getUserMedia,
  getPermissionStatus,
} from '../../utils';
import React, { useEffect, useState } from 'react';

const Permission = props => {
  const {
    setCurrentView,
    VIEWS,
    setLocalStreamError,
    permissionGranted,
    setPermissionGranted,
  } = props;
  const [loading, setLoading] = useState(true);

  const updatePermission = async () => {
    await getPermissionStatus()
      .then(permission => {
        if (permission) {
          setPermissionGranted(true);
        } else {
          setPermissionGranted(false);
        }
      })
      .catch(error => {
        console.error({ permissionError: error });
        setPermissionGranted(false);
      });
  };

  useEffect(() => {
    const callUpdatePermission = async () => {
      await updatePermission();
      setLoading(false); //TODO add a loader
    };
    callUpdatePermission();
  }, []);

  useEffect(() => {
    if (permissionGranted) {
      setCurrentView(VIEWS.PREVIEW);
    }
  }, [permissionGranted]);

  const promptPermission = async () => {
    //TODO remove window.stream
    closeMediaStream(window.stream);
    return await getUserMedia({ audio: true, video: true })
      .then(stream => {
        window.stream = stream;
        closeMediaStream(window.stream);
        setPermissionGranted(true);
        return true;
      })
      .catch(error => {
        setLocalStreamError(error);
        setPermissionGranted(false);
        return false;
      });
  };

  return (
    <>
      <div
        className=" flex items-center justify-center w-full px-4 sm:px-6 lg:px-8"
        style={{
          backgroundColor: '#0B0F15',
          minHeight: 'calc(100vh - 64px)',
        }}
      >
        <div className="overflow-hidden shadow rounded-lg max-w-sm w-full px-4 py-5 p-6 bg-gray-100 my-3">
          <div className="">
            <h2 className="mt-2 text-center text-3xl leading-9 font-extrabold text-gray-900">
              100ms Conference
            </h2>

            <p className="mt-2 text-center text-sm leading-5 text-gray-600 mb-2">
              {permissionGranted
                ? 'We will need your permission to use your webcam and microphone.'
                : "You won't be able to access the meeting unless you grant camera and mic permissions"}
            </p>
            <div className="mt-6">
              <button
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                onClick={async () => {
                  promptPermission().then(permission => {
                    if (permission) {
                      setCurrentView(VIEWS.PREVIEW);
                    }
                  });
                }}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                Prompt permission dialog
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Permission;
