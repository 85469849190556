import React, { useEffect, useRef } from 'react';

export default function SmallVideoView({
  id,
  stream,
  label,
  isMuted,
  isLocal,
  audioEnabled,
  videoEnabled = true,
  screenshare = false,
}) {
  const videoEl = useRef(null);

  useEffect(() => {
    videoEl.current.srcObject = stream;

    return function cleanUp() {
      videoEl.current.srcObject = null;
    };
  }, []);

  label = label || stream.info.name;
  let localLabel = isLocal && label + '(You)';

  return (
    <div
      className={`relative w-64 h-36 mb-2 mx-auto flex justify-center items-center ${
        isLocal ? 'local-video-container' : ''
      }`}
    >
      {!screenshare && !videoEnabled && (
        <div className="absolute left-0 right-0 mx-auto text-center z-10">
          <img
            className="inline rounded-full"
            src={`https://ui-avatars.com/api/?background=random&name=${label}`}
            alt={localLabel || label}
          />
        </div>
      )}
      <video
        ref={videoEl}
        id={id}
        autoPlay
        playsInline
        muted={isMuted}
        className="rounded max-h-full mx-auto shadow-lg"
      />
      <div className="absolute left-0 top-0 w-full text-center pt-0.5">
        <a className="bg-indigo-900 bg-opacity-75 text-xs text-white inline-flex items-center px-1 rounded-sm">
          {localLabel || label}
        </a>
      </div>
    </div>
  );
}
