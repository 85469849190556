export const ROLES = {
  HOST: 'host',
  GUEST: 'guest',
  LIVE_RECORD: 'live-record',
  VIEWER: 'viewer',
};

export const ENVS = {
  QA_IN: 'qa-in',
  PROD_IN: 'prod-in',
  DEV3: 'dev3-in',
  QA_US: 'qa-us',
  PROD_US: 'prod-us',
};
